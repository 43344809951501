document.addEventListener("DOMContentLoaded", function (event) {




    $('.js__trigger .js__trigger-catalog').hover(
        function () {
            if(window.innerWidth > 1200) {
                $('.header-catalog').addClass('open')
            }
        },
    );
    $(".wrapper").bind("mouseover", function (e) {
        if ($(e.target).closest(".header-catalog, .js__trigger-catalog").length === 0 ) {
            if(window.innerWidth > 1200){
                $('.header-catalog').removeClass('open')
            }
        }
    });




    let burgerTrigger = $(".burger");
    let headerMenu = $('.header-catalog');
    let header = $('.header');

    burgerTrigger.bind("click", function () {
        $(this).toggleClass('active');
        headerMenu.toggleClass('open');
        $(".wrapper").toggleClass('active')
    });

    $(".header-catalog__close").bind("click", function () {
        $(burgerTrigger).trigger("click")
    });


    $(".wrapper").bind("click", function (e) {
        if ($(e.target).closest(".header").length === 0 ) {
            if(window.innerWidth < 1200){
                headerMenu.removeClass("open");
                $(".burger").removeClass("active");
                $(".wrapper").removeClass('active')
            }
        }
    });
    $(".wrapper").bind("click", function (e) {
        if ($(e.target).closest(".catalog_menu--page").length === 0 && $(e.target).closest(".bay_catalog").length === 0  ) {
            if(window.innerWidth < 1200){
                $(".catalog_menu--page").removeClass('open')
            }
        }
    });

    // let prevScrollpos = window.pageYOffset;
    //
    // window.onscroll = function () {
    //     let currentScrollPos = window.pageYOffset;
    //     prevScrollpos = currentScrollPos;
    //
    //     if (prevScrollpos > 50) {
    //         header.addClass('bg');
    //     } else {
    //         header.removeClass('bg');
    //     }
    // };
    //
    //
    // $('.js__accordion').click(function (e) {
    //     $(this).toggleClass('active')
    //     $(this).next().slideToggle()
    // });
    //
    // $(".sidebar-catalog__btn").click(function () {
    //     $('.sidebar-catalog').toggleClass('active');
    // });
    //
    // $('.sidebar-catalog__close').click(function () {
    //     $(".sidebar-catalog__btn").trigger('click');
    // });
    //
    // $('.product-accordion__nav').click(function () {
    //     $(this).toggleClass('active')
    //     $(this).next().slideToggle()
    // });




    // $('.product-slider-main__big').magnificPopup({
    //     delegate: 'a', // child items selector, by clicking on it popup will open
    //     type: 'image'
    //     // other options
    // });


    // $('.product-slider-main__big__item').magnificPopup({
    //     type: 'image',
    //     mainClass: 'mfp-with-zoom',
    //     gallery:{
    //         enabled:true
    //     },
    //
    //     zoom: {
    //         enabled: false,
    //         duration: 300, // duration of the effect, in milliseconds
    //         easing: 'ease-in-out', // CSS transition easing function
    //
    //         opener: function(openerElement) {
    //             return openerElement.is('img') ? openerElement : openerElement.find('img');
    //         }
    //     }
    //
    // });

// // add active class menu
// let url = window.location.pathname.replace('/', '');
//
// $('a.footer-menu__link, a.header-menu-top-list__link').each(function () {
//     if($(this).attr("href") == url){
//         $(this).addClass('active');
//     }
// });

    //breaks up the section into rows of size 'rowSize', 2 by default.
    function breakitup(rowSize = 14) {
        var $section = $(".js__masonry-menu"); //get the section
        var $row = $("<div class='col-md-3'>"); //make a template for a row
        var cols = $section.find(".firstnavi"); //get the columns
        var working; //tmp variable for the current row

        for (var i = 0; i < cols.length; i++) {
            if (i % rowSize == 0) working = $row.clone(); //clone the template when appropriate
            $(working).append(cols[i]); //add the column to the row
            if ($(working).children().length == rowSize)
                $section.append(working); //add the row to the section as appropriate
        }
        $section.append(working); //add the last row
    }
//call our fancy function
 if(window.innerWidth > 992){
     breakitup();
 }
    let cloneMegaMenu =  $('.js__masonry-menu').clone();
    $('.header-catalog__mob-menu  .js__trigger').append(cloneMegaMenu)

    $('.parent > a').click(function () {
        $(this).toggleClass('active');
        $(this).next().slideToggle();
    });

    $('a.js__trigger-catalog').bind('click', function (e) {
        if(window.innerWidth < 992){
            e.preventDefault();
            $(this).toggleClass('active')
            $(this).next().slideToggle();
        }
    });

    $('.bay_catalog').click(function () {
        $('.catalog_menu--page').addClass('open');
    });

    $('.catalog_menu--page-close').click(function () {
        $('.catalog_menu--page').removeClass('open');
    });



});
